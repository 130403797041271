body {
    font-size: 14px;
    line-height: 1.42857143;
}
.form-control {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}
.bm-burger-button {
    top: 50px !important;
    width: 20px !important;
    height: 20px !important;
    left: 20px !important;
}

iframe {
    border: none;
    margin-top:10px;
}

.institute-top-intro{
    text-align:center;
}

.login{
    text-align:center;
}

.has-error{
    border-bottom: 1px solid tomato !important;
}

.orange-card {
    background-image: -webkit-linear-gradient(0deg, #fb853f 0%, #fda446 37%, #fec24d 99%);
}

.coming-soon-button{
    background: #162e6d;;
    font-weight: 600;
}

.launch-button{
    background: #2c81f6;
}

.register-button{
    background: var(--yellow);
}

.result-button{
    background: #08bd80;
}

.time-out-button{
    background: #C53D3D;
}

.red-card {
    background-image: -webkit-linear-gradient(0deg, #ff6072 0%, #ff9842 100%);
}

.blue-card {
    background-image: -webkit-linear-gradient(0deg, #1d7ed5 0%, #15bae3 100%);
}

.green-card {
    background-image: -webkit-linear-gradient(0deg, #5da315 0%, #91cb14 100%);
}

.blue-light-card {
    background-image: -webkit-linear-gradient(0deg, #53a1a2 0%, #8dccaa 100%);
}

.pink-card {
    background-image: -webkit-linear-gradient(0deg, #f6667b 0%, #f19af8 100%);
}

.blue-full-card {
    background-image: -webkit-linear-gradient(0deg, #13a7fc 0%, #14e6ce 100%);
}

.grey-card {
  background-image: -webkit-linear-gradient(0deg, rgb(83, 161, 162) 0%, rgb(141, 204, 170) 100%);
}

.module-header {
    left: 0;
    top: 0;
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
}

.module-header-inner {
    height: 130px;
	text-align:center;
}

.module-header-inner img {
    width: 75px;
    height: auto;
    margin-top: 20px;
}

.module-header-step2 {
    position: fixed;
    width: 100%;
    top: 0px;
	text-align:center;
	padding: 4px;
	color:#fff;
	z-index:999;
}

.arrow-set::after {
    z-index: 1;
    position: absolute;
    top: 98.1%;
    left: 70%;
    margin-left: -25%;
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
}
.arrow-set::after {
    margin-left: -23% !important;
}

.arrow-set {
    -webkit-box-shadow: 0px 5px 5px -4px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 5px -4px rgba(0, 0, 0, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background:white;
}
.arrow-set h4{
    margin: 0;
	font-size:18px;
}

.back {
    min-height: 50px;
    display: flex;
    left: 0;
    top: 0px;
    width: 100%;
    padding: 15px;
    color: #fff;
    background: hsla(0,0%,5.4%,.25);
    position: fixed;
    justify-content: flex-start;
}

.back path{
    fill: #fefefe;
}

/*.card {
    border-top: none;
	border-left: none;
	border-right: none;
    border-radius: 0px;
    text-align: left;
}*/

.card-body {
    padding: 0.75rem !important;
}

/*.cardbody{
    width:97%;
    background:#fff;
    min-height:60px;
    margin:5px;
    padding: 5px;
}*/
.card_list h4{
  font-size:18px;
  margin:10px;
}
.card_list .cardbody img{
    width:50px;float:left;padding:5px;border-radius: 40px;height: 50px;
}
.cardbody h4.head{
    color:#333;float: left;margin: 14px;
}
.cardbody svg{
    float: right;margin: 15px;
}

.Book{
    float: left !important;
    margin: 10px !important;
}

#Attachment{
    border: 1px solid #ccc;
    padding: 3px;
    text-align: center;
    margin-top: 10px;
    background: #f6f6f6;
}

.mt60{
	margin-top:60px;
}

.mt50{
	margin-top:50px;
}

.module-header-step2 h4{
    font-size: 18px;
	margin-top: 10px;
	margin-bottom: 7px;
	text-align: center;
	padding: 0px 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}
.card_details img, .message_details img{
  max-width: 100%;
  height: auto;
}

.ilearn-plain-header {
    position: sticky;
    width: 100%;
    top: 0px;
    text-align: center;
    z-index: 1;
}

.card-header h2{
   font-size:21px !important;
}

.ilearn-plain-header h4 { 
    margin: 0;
    padding: 15px;
    color:#fff;
	font-size:18px;
}

.ilearn-item-block  h4{
   font-size:18px;
}

.ilearn-item-block {
    margin: 0;
    padding: 0;
    text-align: initial;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    /*min-height: 4.4rem;*/
    border: 0;
    font-weight: normal;
    line-height: normal;
    text-decoration: none;
    color: inherit;
  }
  
  .ilearn-avatar {
    
      line-height: 1;
      /*background: #5754bc;*/
      min-width: 45px;
      min-height: 45px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-top: 9px;
      width:40px;
      height: 40px;
      float:left;
      padding:5px;
      border-radius: 40px;
  }
  
  .ilearn-item-inner {
    color:#333;
    float: left;
    font-size: 15px;
    width:100%;
    text-align:left;
    padding-left: 10px;
  }

  .ilearn-padding {
      padding: 5px;
  }

  .ilearn-padding-both-sides {
    padding-left: 5px;
    padding-right: 5px;
  }

  .ilearn-padding-top {
    padding-top: 5px;
  }

  .ilearn-full-height {
    height: 100vh;
  }

.ilearn-orange-btn {
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(251, 133, 63) 0%,
      rgb(253, 164, 70) 37%,
      rgb(254, 194, 77) 99%
    );
    border-color: rgb(251, 133, 63);
    border: rgb(251, 133, 63) 1px solid;  
}
  
.ilearn-red-btn {
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(255, 96, 114) 0%,
      rgb(255, 152, 66) 100%
    );
    border-color: rgb(255, 96, 114); 
    border: rgb(255, 96, 114) 1px solid; 
}
  
.ilearn-blue-btn {
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(29, 126, 213) 0%,
      rgb(21, 186, 227) 100%
    );
    border-color: rgb(29, 126, 213);
    border: rgb(29, 126, 213) 1px solid;   
}
  
.ilearn-green-btn {
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(93, 163, 21) 0%,
      rgb(145, 203, 20) 100%
    );
    border-color:rgb(93, 163, 21);
    border: rgb(93, 163, 21) 1px solid;  
}
  
.ilearn-blue-light-btn {
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(19, 167, 252) 0%,
      rgb(20, 230, 206) 100%
    );
    border-color:rgb(19, 167, 252);
    border: rgb(19, 167, 252) 1px solid;  
}
  
.ilearn-pink-btn {
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(246, 102, 123) 0%,
      rgb(241, 154, 248) 100%
    );
    border-color:rgb(246, 102, 123);
    border: rgb(246, 102, 123) 1px solid;  
}
  
.ilearn-grey-btn {
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(83, 161, 162) 0%,
      rgb(141, 204, 170) 100%
    );
    border-color: rgb(83, 161, 162);
    border: rgb(83, 161, 162) 1px solid; 
}

.ilearn-footer {
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: 10;
    display: block;
    width: 100%;    
}

.ilearn-footer-col {
    padding-left:1px !important;
    padding-right:1px !important;
    display: inherit;
}

.ilearn-footer-col .btn{
    padding:10px;
}
  
.ilearn-footer-btn,  .ilearn-footer-btn:hover, .ilearn-footer-btn:focus{
    width: 100%;
    color: #fff;
    border-radius: 0px;
}

.ilearn-item-border {
    border: 2px solid rgba(0,0,0,.125);
}
.ilearn-item-border:last-child {
    border: none;
}

.ilearn-icon-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
}

.ilearn-btn-right {
    float: right;
    padding: 5px;
    color: #fff;
    font-size: 11px;
    min-width:80px;
    width: 80px;
    border: none;
    text-transform: uppercase;
  }
.no_border_lrt{
    border-left: 0;
	border-right: 0;
	border-top: 0px;
}
.divhide{
   display:none;
}

@media only screen and (min-device-width :1024px) and (max-device-width :1824px) {
  .arrow-set::after {
      margin-left: -21% !important;
  }
}

.error-message{
    color:tomato !important;
}

.center {
    margin: auto;
    width: 50%;
  }