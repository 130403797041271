@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,400;0,600;1,800&display=swap");


@import './_assets/fonts/icon-fonts/regular/style.css';

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Kruti Dev 010";
  src: url("fonts/Kruti_Dev.ttf");
}

@font-face {
  font-family: "digital-clock-font";
  src: url("fonts/digital-7.ttf");
}



div{
  background: transparent;
  background-color: transparent;
}

.card{
  background-color: transparent;
}
